@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  background-color: #ffffff !important;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.m-b {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.m-s-b {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.m-m {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

/* SCROLL BLUE */
.scroll-page-blue::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 5px !important;
}

.scroll-page-blue::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #2743a6 !important;
  border-radius: 5px !important;
}

.scroll-page-blue::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background-color: transparent !important;
}

/* END SCROLL BLUE */

/* SCROLL GRY */
.scroll-page-gry::-webkit-scrollbar {
  width: 5px !important;
}

.scroll-page-gry::-webkit-scrollbar-thumb {
  background-color: #d1d2d5 !important;
  border-radius: 5px !important;
}

.scroll-page-gry::-webkit-scrollbar-track {
  background-color: transparent !important;
}

/* END SCROLL GRY */

#check-in:active {
  border: none !important;
}

.bg-flag-es {
  background-image: url("https://sandboxmexico.com/assets/icons/leng/es.svg");
  background-repeat: no-repeat;
  background-size: 20px 14px;
  background-position: 10px center;
}

.bg-flag-us {
  background-image: url("ttps://sandboxmexico.com/assets/icons/leng/en.svg");
  background-repeat: no-repeat;
  background-size: 20px 14px;
  background-position: 10px center;
}

.fixed-height-container {
  width: 100%;  
  height: 400px;  
  position: relative;
  overflow: hidden; 
}

.fixed-height-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto; 
  object-fit: contain; 
  transform: translate(-50%, -50%); 
}
